import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { casesResponse } from "../../redux/actionTypes/caseActionType";

interface PaginationInterface {
    data: {
        count: number,
        data: any[],
        totalPages: number,
        currentPage: number
    } | null | undefined,
    setpage: React.SetStateAction<any>,
    search: string,
    setsearch: React.SetStateAction<any>,
    page: number
    gotoPage: (page: number) => void
}

const Pagination: React.FC<PaginationInterface> = ({ gotoPage, data, search, setpage, page, ...otherprops }) => {
    let dispatch = useDispatch();

    const [totalPages, settotalPages] = useState<number[]>([])
    const [firstPage, setfirstPage] = useState(true)
    const [lastPage, setlastPage] = useState(false)

    useEffect(() => {
        // if (cases?.success) {
            let totalPage: number[] = []
            const total = data?.totalPages as number
            for (let i = 0; i < total; i++) {
                totalPage.push(i);
                settotalPages(totalPage)
            }

            if (Math.abs(data?.totalPages as number - page) <= 2) {
                setlastPage(true);
            } else {
                setlastPage(false);
            }

            if (page <= 1) {
                setfirstPage(true)
            } else {
                setfirstPage(false)
            }
        // }
    }, [data, page])
    return (
        <div className="flex items-center">
            <button onClick={() => gotoPage(page - 1)} type="button" disabled={page <= 1 ? true : false} className="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
                    </path>
                </svg>
            </button>
            {
                firstPage ? <></> : <>
                    <button onClick={() => gotoPage(1)} key={`btnPagination-first`} type="button" className="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 ">
                        {1}
                    </button> ...
                </>
            }
            {
                totalPages.map((e) => {
                    if (Math.abs(e - page) < 2) {
                        return (
                            <button onClick={() => gotoPage(e + 1)} key={`btnPagination-${e}`} type="button" className="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 ">
                                {e + 1}
                            </button>
                        )
                    }
                })
            }
            {
                lastPage ? <></> : <>
                    ...
                    <button onClick={() => gotoPage(totalPages.length)} key={`btnPagination-first`} type="button" className="w-full px-4 py-2 border-t border-b text-base text-indigo-500 bg-white hover:bg-gray-100 ">
                        {totalPages.length}
                    </button>
                </>
            }
            <button onClick={() => gotoPage(page + 1)} disabled={page === totalPages.length ? true : false} type="button" className="w-full p-4 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                    </path>
                </svg>
            </button>
        </div>
    )
}
export default Pagination;