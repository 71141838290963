import { actionCheckAuth, checkauthActionType, checkAuthInterface } from '../actionTypes/checkauthActionType';
interface State {
    process: boolean;
    authenticated: checkAuthInterface | null;
    unauthenticated: boolean | null;
}

const initialState = {
    process: true,
    authenticated: {
        isLoggedIn: null,
        email: null,
        roles: []
    },
    unauthenticated: null,
}

const checkAuthReducer = (state: State = initialState, action: actionCheckAuth): State => {
    switch (action.type) {
        case checkauthActionType.CHECK_AUTH_PENDING:
            return {
                process: true,
                authenticated: initialState.authenticated,
                unauthenticated: initialState.unauthenticated,
            }
        case checkauthActionType.CHECK_AUTH_SUCCESS:
            return {
                process: false,
                authenticated: action.payload,
                unauthenticated: initialState.unauthenticated,
            }
        case checkauthActionType.CHECK_AUTH_FAIL:
            return {
                process: false,
                authenticated: initialState.authenticated,
                unauthenticated: true,
            }
        default:
            return state
    }
}

export default checkAuthReducer;