import { Dispatch } from 'redux';
import getApi from '../../commons/axios/getApi';
import { actionCategory, categoryActionType } from '../actionTypes/categoryActionType';

interface category {
    name: string,
}

export const createCategory = (params: category) => {
    return async (dispatch: Dispatch<actionCategory>) => {
        dispatch({
            type: categoryActionType.GET_POST_CATEGORY_PENDING
        });

        try {
            const { data } = await getApi.post('/case-category/create', params);
            if (data.success) {
                dispatch({
                    type: categoryActionType.GET_POST_CATEGORY_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({
                    type: categoryActionType.GET_POST_CATEGORY_FAIL,
                    payload: data
                });
            }

        } catch (err: any) {
            dispatch({
                type: categoryActionType.GET_POST_CATEGORY_FAIL,
                payload: err.message
            });
        }
    }
}
export const getCategory = () => {
    return async (dispatch: Dispatch<actionCategory>) => {
        dispatch({
            type: categoryActionType.GET_POST_CATEGORY_PENDING
        });

        try {
            const { data } = await getApi.get('/case-category/');

            if (data.success) {
                dispatch({
                    type: categoryActionType.GET_POST_CATEGORY_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({
                    type: categoryActionType.GET_POST_CATEGORY_FAIL,
                    payload: data
                });
            }

        } catch (err: any) {
            dispatch({
                type: categoryActionType.GET_POST_CATEGORY_FAIL,
                payload: err.message
            });
        }
    }
}