import ChatIcon from "../components/Icons/ChatIcon"
import ClipboardIcon from "../components/Icons/Clipboard"
import IconDashboard from "../components/Icons/Dashboard"
import PlusIcon from "../components/Icons/Plus"
import IconWarning from "../components/Icons/Warning"

interface menu {
    label: string,
    icon: JSX.Element,
    link: string
}
const SidebarMenu: menu[] = [
    {
        label: 'Home',
        link: '/',
        icon: <IconDashboard />
    }, {
        label: 'New Case',
        link: '/new-case',
        icon: <PlusIcon />
    }, {
        label: 'Opened Cases',
        link: '/opened-case',
        icon: <ChatIcon />
    }, {
        label: 'History',
        link: '/history-case',
        icon: <ClipboardIcon />
    }
]

export default SidebarMenu