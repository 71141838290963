import { ProfileInterface } from "../interfaces/User";
import Calendar from 'react-calendar';
import { useEffect, useState } from "react";
import 'react-calendar/dist/Calendar.css';
import { useTypedSelector } from "../hooks/useTypeSelector";
import { useDispatch } from "react-redux";
import { getCase } from "../redux/actionCreators/caseAction";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { checkAuth } from "../redux/actionCreators/authAction";

const DashboardPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setemail] = useState('');
    const [date, setDate] = useState(new Date());
    const { cases } = useTypedSelector((state) => state.case);
    const [first, setfirst] = useState(true)
    const [onProgress, setonProgress] = useState(0);
    const [unHandle, setunHandle] = useState(0);
    const [closed, setclosed] = useState(0);
    let user = localStorage.getItem('user') as string;
    const userData: ProfileInterface = JSON.parse(user)

    useEffect(() => {
        if (first) {
            dispatch(getCase(1, 1000000000000));
            let jwt = localStorage.getItem('access_token') as string;
            if (jwt) {
                const { email } = jwtDecode<{ email: string }>(jwt);
                setemail(email)
            }
            setfirst(false)
        }
        if (cases?.success) {
            let undhand = 0;
            let close = 0;
            let onprog = 0;
            for (let i = 0; i < cases?.payload.data.length; i++) {
                const e = cases?.payload.data[i];
                if (e.status === 1) {
                    undhand++
                } else if (e.status === 2) {
                    onprog++
                } else if (e.status === 3) {
                    close++
                }
            }
            setonProgress(onprog);
            setclosed(close);
            setunHandle(undhand);
        }

    }, [cases?.payload.data, cases?.success, dispatch])
    return (
        <>
            <div className="mb-4 grid grid-cols-3 gap-4 grid-flow-row auto-rows-max">
                <div className="col-span-3 lg:col-span-2 grid-flow-row-dense">
                    <div className="text-2xl mb-10">
                        Howdy, {userData?.firstName} {userData?.lastName} ?
                    </div>
                    <div className="col-span-2">
                        <div className="mb-4 grid grid-cols-3 gap-4">
                            <div className="col-span-3 md:col-span-1 shadow-lg rounded-2xl w-full p-4 bg-white dark:bg-gray-800">
                                <div className="flex items-center">
                                    <span className="bg-danger-500 p-2 h-4 w-4 rounded-full relative">
                                        <svg width="20" fill="currentColor" height="20" className="text-white h-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                            </path>
                                        </svg>
                                    </span>
                                    <p className="text-md text-gray-700 dark:text-gray-50 ml-2">
                                        Unprogress case
                                    </p>
                                </div>
                                <div className="flex flex-col justify-start">
                                    <p className="text-gray-800 text-4xl text-left dark:text-white font-bold my-4">
                                        {unHandle}
                                    </p>
                                    <div className="relative w-full h-2 bg-gray-200 rounded">
                                        <div className="absolute top-0 h-2  left-0 rounded bg-danger-500 w-full">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-3 md:col-span-1 shadow-lg rounded-2xl w-full p-4 bg-white dark:bg-gray-800">
                                <div className="flex items-center">
                                    <span className="bg-warning-500 p-2 h-4 w-4 rounded-full relative">
                                        <svg width="20" fill="currentColor" height="20" className="text-white h-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                            </path>
                                        </svg>
                                    </span>
                                    <p className="text-md text-gray-700 dark:text-gray-50 ml-2">
                                        Case on handling
                                    </p>
                                </div>
                                <div className="flex flex-col justify-start">
                                    <p className="text-gray-800 text-4xl text-left dark:text-white font-bold my-4">
                                        {onProgress}
                                    </p>
                                    <div className="relative w-full h-2 bg-gray-200 rounded">
                                        <div className="absolute top-0 h-2  left-0 rounded bg-warning-500 w-full">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1 shadow-lg rounded-2xl w-full p-4 bg-white dark:bg-gray-800">
                                <div className="flex items-center">
                                    <span className="bg-zinc-500 p-2 h-4 w-4 rounded-full relative">
                                        <svg width="20" fill="currentColor" height="20" className="text-white h-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                            </path>
                                        </svg>
                                    </span>
                                    <p className="text-md text-gray-700 dark:text-gray-50 ml-2">
                                        Closed Cases
                                    </p>
                                </div>
                                <div className="flex flex-col justify-start">
                                    <p className="text-gray-800 text-4xl text-left dark:text-white font-bold my-4">
                                        {closed}
                                    </p>
                                    <div className="relative w-full h-2 bg-gray-200 rounded">
                                        <div className="absolute top-0 h-2  left-0 rounded bg-gray-500 w-full">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-lg mt-4 rounded-xl w-full md:w-full p-4 bg-white dark:bg-gray-800 relative overflow-hidden">
                            <div className="w-full flex items-center justify-between mb-8">
                                <p className="text-gray-800 dark:text-white text-xl font-normal">
                                    CASES HISTORY
                                </p>
                                <button onClick={() => navigate('/opened-case')} className="flex items-center text-sm hover:text-gray-600 dark:text-gray-50 dark:hover:text-white text-gray-300 border-0 focus:outline-none">
                                    VIEW ALL
                                </button>
                            </div>
                            {
                                cases?.payload.data.map((e, i) => {
                                    if (i < 6) {
                                        return (
                                            <div key={`cases-history-${i}`} className="flex items-start mb-6 rounded justify-between">
                                                <span className={`rounded-full text-white dark:text-gray-800 p-2 ${e.status == 1 ? 'bg-danger-400' : ''} ${e.status == 2 ? 'bg-warning-400' : ''} ${e.status == 3 ? 'bg-success-400' : ''}`}>
                                                    <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                                                        </path>
                                                    </svg>
                                                </span>
                                                <div className="flex items-center w-full justify-between">
                                                    <div className="flex text-sm flex-col w-full ml-2 items-start justify-between">
                                                        <p className="text-gray-700 dark:text-white break-words">
                                                            <span className="font-bold mr-1 break-words">
                                                                {e.createdBy}
                                                            </span>
                                                            {e.name}
                                                        </p>
                                                        <p className="text-gray-300 break-words">
                                                            {new Date(e.createdAt as string).toLocaleString()}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>


                    </div>
                </div>
                <div className="p-0 mx-auto lg:col-span-1 col-span-3 max-h-full w-full">
                    <Calendar className={`bg-success-25 border-sky-500 shadow-md lg:block hidden rounded-xl`} onChange={(e: any) => console.log(e)} value={date} />

                    <div className="shadow-lg mt-6 rounded-2xl bg-white dark:bg-gray-700 w-full">
                        <p className="font-bold text-md p-4 text-black dark:text-white">
                            My Case
                        </p>
                        <ul>
                            {
                                cases?.payload.data.map((e, i) => {
                                    if (e.createdBy === email && i < 8) {
                                        return (
                                            <li key={`cases-mine-${i}`} className="break-words flex items-center text-gray-600 dark:text-gray-200 justify-between py-3 border-b-2 border-gray-100 dark:border-gray-800">
                                                <div className="flex items-center justify-start text-sm">
                                                    <span className="mx-4">
                                                        {i + 1}
                                                    </span>
                                                    <span>
                                                        {e.name}
                                                    </span>
                                                </div>
                                                <svg width="20" height="20" fill="currentColor" className={`mx-4 ${e.status === 3 ? 'text-success-400' : 'text-warning-400'} dark:text-gray-300`} viewBox="0 0 1024 1024">
                                                    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" fill="currentColor">
                                                    </path>
                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z" fill="currentColor">
                                                    </path>
                                                </svg>
                                            </li>
                                        )
                                    }

                                })
                            }
                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DashboardPage;