const DropDownMenuProfile = () => {
    return (
        <>
            <div className="z-40 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
                <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {/* <a href="" className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                        <span className="flex flex-col">
                            <span>
                                Settings
                            </span>
                        </span>
                    </a>
                    <a href="#" className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                        <span className="flex flex-col">
                            <span>
                                Account
                            </span>
                        </span>
                    </a> */}
                    <a href="/logout" className="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                        <span className="flex flex-col">
                            <span>
                                Logout
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </>
    )
}

export default DropDownMenuProfile;