interface ContentInterface {
    children?: JSX.Element,
}

const MainContent: React.FC<ContentInterface> = ({
    children,
    ...otherProps
}) => {
    return (
        <div {...otherProps} className="bg-white dark:bg-gray-800 shadow mx-4 p-10 min-h-screen">
            {children}
        </div>
    )
}

export default MainContent;