import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "../components/Navigations/Header";
import Sidebar from "../components/Navigations/Sidebar";
import Footer from "../components/Sections/Footer";
import MainContent from "../components/Sections/MainContent";
import { checkAuth } from "../redux/actionCreators/authAction";
import { getCategory } from "../redux/actionCreators/categoryAction";
import SidebarMenu from "./SidebarMenu";

const AdminLayout = () => {

    let dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(checkAuth());
        dispatch(getCategory());

    }, [null])
    return (
        <>
            <div className="grid grid-cols-7">
                <Sidebar data={SidebarMenu} />
                <div className="lg:col-start-2 col-span-7 lg:ml-32">
                    <Header items={[]} />
                    <MainContent>
                        <>
                            <Outlet />
                        </>
                    </MainContent>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default AdminLayout;