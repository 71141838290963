import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "../../components/Tables/Pagination";
import { useTypedSelector } from "../../hooks/useTypeSelector";
import { getCase } from "../../redux/actionCreators/caseAction";

const OpenCase = () => {
    let dispatch = useDispatch();
    const [search, setsearch] = useState('')
    const [page, setpage] = useState<number>(1)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setsearch(event.target.value)
    }

    const submitSearch = () => {
        dispatch(getCase(1, 10, {
            skipclosed: 'yes',
            title: search,
        }))
    }
    const { cases } = useTypedSelector((state) => state.case);
    const headers = [
        'Case ID', 'Title', ' PIC', ' Status', 'Created By'
    ]
    const gotoPage = (num: number) => {
        setpage(num)
    }

    useEffect(() => {
        dispatch(getCase(page, 10, {
            skipclosed: 'yes',
            title: search
        }));
    }, [page])

    return (
        <>

            <div className="container mx-auto px-4 sm:px-8 max-w-full">
                <div className="py-8">
                    <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
                        <h2 className="text-2xl leading-tight">
                            Total : {cases?.payload.count}
                        </h2>
                        <div className="text-end">
                            <div className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
                                <div className="relative ">
                                    <input onKeyPress={(e) => { if (e.key === 'Enter') submitSearch() }} onChange={handleChange} value={search} type="text" id="&quot;form-subscribe-Filter" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="search title" />
                                </div>
                                <button onClick={submitSearch} className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="button">
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        {
                                            headers.map((e, i) => {
                                                return (
                                                    <th key={`thead-${i}`} scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                                        {e}
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cases?.payload?.data?.map((e, i) => {
                                            return (
                                                <tr key={`rows-${i}`}>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                            {e.caseNumber}
                                                        </p>
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                            {e.name}
                                                        </p>
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                            {e.pic + ' | ' + e.picNumber}
                                                        </p>
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <span className={`relative inline-block px-3 py-1 font-semibold  text-gray-900 leading-tight`}>
                                                            <span aria-hidden="true" className={`absolute inset-0 ${e.status === 1 ? 'bg-danger-400' : 'bg-warning-400'} opacity-50 rounded-full`}>
                                                            </span>
                                                            <span className="relative">
                                                                {e.case_status?.name}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap">
                                                            {e.createdBy}
                                                        </p>
                                                    </td>
                                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <Link to={`${e.id}`} className="text-indigo-600 hover:text-indigo-900">
                                                            Proses
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
                                {<Pagination gotoPage={gotoPage} setsearch={setsearch} search={search} data={cases?.payload} setpage={setpage} page={page} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OpenCase