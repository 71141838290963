
import { actionCategory, categoryActionType, CategoryResponse } from '../actionTypes/categoryActionType';

interface State {
    category: CategoryResponse | null;
    loading: boolean;
    error: {
        status: boolean,
        message?: string,
        validations?: any
    };
}

const initialState = {
    category: {
        success: false,
        payload: {
            id: null,
            name: null
        },
        errors: null,
    },
    loading: false,
    error: {
        status: false,
    }
}

const CategoryReducer = (state: State = initialState, action: actionCategory): State => {
    switch (action.type) {
        case categoryActionType.GET_POST_CATEGORY_PENDING:
            return {
                loading: true,
                category: initialState.category,
                error: {
                    status: false
                }
            }
        case categoryActionType.GET_POST_CATEGORY_SUCCESS:
            return {
                loading: false,
                category: action.payload,
                error: {
                    status: false
                }
            }
        case categoryActionType.GET_POST_CATEGORY_FAIL:
            return {
                loading: false,
                category: null,
                error: {
                    status: true,
                    message: action.payload.errors ?? action.payload.message,
                    validations: action.payload.validationErrors,
                },
            }
        default:
            return state;
    }
}

export default CategoryReducer;