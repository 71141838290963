import { useEffect, useState } from "react";
import { useNavigate, } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypeSelector";


export function PrivateAuth({ children }: { children: JSX.Element, auth?: boolean | null }) {
    const { process, unauthenticated } = useTypedSelector((state) => state.checkAuth);
    const navigate = useNavigate();

    useEffect(()=>{
        if (unauthenticated) {
            return navigate('/login')
        }
        
    },[navigate, process, unauthenticated])

    return children;
}

export function RouteNoAuth({ children }: { children: JSX.Element, auth?: boolean | null }) {
    const { process, unauthenticated, authenticated } = useTypedSelector((state) => state.checkAuth);
    const [first, setfirst] = useState(true);

    // useEffect(() => {
    //     dispatch(checkAuth())

    //     if ((authenticated?.isLoggedIn)) {
    //         return navigate('/login')
    //     }

    // }, [authenticated?.isLoggedIn, dispatch, navigate])

    return children;
}