export interface checkAuthInterface {
    isLoggedIn: boolean | null,
    email: string | null,
    roles: string[] | []
}

export enum checkauthActionType {
    CHECK_AUTH_PENDING = 'CHECK_AUTH_PENDING',
    CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS',
    CHECK_AUTH_FAIL = 'CHECK_AUTH_FAIL'
}
interface checkingAuth {
    type: checkauthActionType.CHECK_AUTH_PENDING;
}

interface actionAuthenticated {
    type: checkauthActionType.CHECK_AUTH_SUCCESS;
    payload: checkAuthInterface;
}
interface actionUnauthenticated {
    type: checkauthActionType.CHECK_AUTH_FAIL;
}

export type actionCheckAuth = actionAuthenticated | actionUnauthenticated | checkingAuth;