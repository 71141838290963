import jwtDecode from 'jwt-decode';
import { Dispatch } from 'redux';
import authApi from '../../commons/axios/authApi';
import getApi from '../../commons/axios/getApi';
import { jwtToken } from '../../types/auth.interface';
import { authActionType, actionAuth } from '../actionTypes/authActionType';
import { actionCheckAuth, checkauthActionType } from '../actionTypes/checkauthActionType';

interface login {
    email: string,
    password: string
}

export const postLogin = (params: login) => {
    return async (dispatch: Dispatch<actionAuth>) => {
        dispatch({
            type: authActionType.GET_POST_AUTH_PENDING
        });

        try {
            const { data } = await authApi.post('/auth/login', params);

            if (data.success) {
                const user = data.payload.user_profile;

                localStorage.setItem('access_token', data.payload.access_token as string)
                localStorage.setItem('refresh_token', data.payload.refresh_token as string)
                localStorage.setItem('user', JSON.stringify(user));
                dispatch({
                    type: authActionType.GET_POST_AUTH_SUCCESS,
                    payload: data
                });
            } else {
                dispatch({
                    type: authActionType.GET_POST_AUTH_FAIL,
                    payload: data
                });
            }

        } catch (err: any) {
            dispatch({
                type: authActionType.GET_POST_AUTH_FAIL,
                payload: err.message
            });
        }
    }
}

export const postLogout = () => {
    return async (dispatch: Dispatch<actionCheckAuth>) => {
        dispatch({
            type: checkauthActionType.CHECK_AUTH_PENDING
        });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        dispatch({
            type: checkauthActionType.CHECK_AUTH_FAIL
        });

    }
}

export const checkAuth = () => {
    return async (dispatch: Dispatch<actionCheckAuth | actionAuth>) => {
        dispatch({
            type: authActionType.GET_POST_AUTH_PENDING,
        });
        dispatch({
            type: checkauthActionType.CHECK_AUTH_PENDING
        });
        try {
            const token = localStorage.getItem('access_token') as string;
            if (!token) {
                return dispatch({
                    type: checkauthActionType.CHECK_AUTH_FAIL
                });
            }
            const { exp, email } = jwtDecode<jwtToken>(token)

            const expirationTime = (exp * 1000) - 60000
            if (Date.now() >= expirationTime) {
                try {
                    const { data } = await authApi.post('/auth/refresh-token', {
                        headers: {
                            refreshtoken: localStorage.getItem('refresh_token')
                        }
                    });
                    if (data.success) {
                        const user = data.payload.user_profile;

                        localStorage.setItem('access_token', data.payload.access_token as string)
                        localStorage.setItem('refresh_token', data.payload.refresh_token as string)
                        localStorage.setItem('user', JSON.stringify(user));
                        return dispatch({
                            type: checkauthActionType.CHECK_AUTH_SUCCESS,
                            payload: {
                                email: email,
                                isLoggedIn: true,
                                roles: []
                            }
                        });
                    } else {
                        localStorage.removeItem('access_token')
                        return dispatch({
                            type: checkauthActionType.CHECK_AUTH_FAIL
                        });
                    }
                } catch (error) {
                    localStorage.removeItem('access_token')
                    return dispatch({
                        type: checkauthActionType.CHECK_AUTH_FAIL
                    });
                }

            } else {
                return dispatch({
                    type: checkauthActionType.CHECK_AUTH_SUCCESS,
                    payload: {
                        email: email,
                        isLoggedIn: true,
                        roles: []
                    }
                });
            }
        } catch (error) {
            console.log(error)
            return dispatch({
                type: checkauthActionType.CHECK_AUTH_FAIL
            });
        }

    }
}