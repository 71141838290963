import { CaseInterface,CasePaginationInterface } from "../../interfaces/Case";

export interface caseResponse {
    success: boolean | null,
    message?: string,
    payload: CaseInterface,
    errors: any | null,
    validationErrors?: any,
}
export interface casesResponse {
    success: boolean | null,
    message?: string,
    payload: CasePaginationInterface,
    errors: any | null,
    validationErrors?: any,
}
export enum caseActionType {
    CASE_LOADING = 'CASE_LOADING',
    CREATED_CASE = 'CREATED_CASE',
    READ_CASES = 'READ_CASES',
    READ_SINGLE_CASE = 'READ_SINGLE_CASE',
    UPDATE_CASE = 'UPDATE_CASE',
    DELETE_CASES = 'UPDATE_CASE',
    ERROR_CASE = 'ERROR_CASE'
}

interface actionLoading {
    type: caseActionType.CASE_LOADING;
}

interface actionCreate {
    type: caseActionType.CREATED_CASE;
    payload: caseResponse;
}

interface actionReadAll {
    type: caseActionType.READ_CASES;
    payload: casesResponse;
}

interface actionReadOne {
    type: caseActionType.READ_SINGLE_CASE;
    payload: caseResponse;
}

interface actionUpdate {
    type: caseActionType.UPDATE_CASE;
    payload: caseResponse;
}

interface actionDelete {
    type: caseActionType.DELETE_CASES;
    payload: caseResponse;
}

interface actionError {
    type: caseActionType.ERROR_CASE;
    payload: caseResponse;
    status?: number
}

export type actionCase = actionLoading | actionCreate | actionReadAll | actionReadOne | actionUpdate | actionDelete | actionError;