import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import Input from "../../components/Forms/Inputs/Input";
import IconWarning from "../../components/Icons/Warning";
import { useTypedSelector } from "../../hooks/useTypeSelector";
import { Store } from "react-notifications-component";
import Select from 'react-select'
import { CategoryInterface } from "../../interfaces/Category";
import { createCase } from "../../redux/actionCreators/caseAction";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const CreateCase = () => {
    let dispatch = useDispatch();
    const { category, error } = useTypedSelector((state) => state.category);
    const { created, errors } = useTypedSelector((state) => state.case);
    const [Options, setOptions] = useState<{ value: number | null, label: string | null }[]>([])

    const contentBlock = htmlToDraft("")
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    const editorState = EditorState.createWithContent(contentState)
    const [content, setContent] = useState(editorState)

    const [InputForm, SetInputForm] = useState({
        name: '',
        description: '',
        pic: '',
        picNumber: '',
        documents: [],
        docPreview: [] as string[],
        category: ''
    });

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        SetInputForm({
            ...InputForm,
            [event.target.name]: event.target.value,
        })
    }

    function handleEditor(event: any) {
        setContent(event)
    }

    function handleupload(event: React.ChangeEvent<HTMLInputElement>) {
        let tmp = event.target.files ?? []
        let arr = []
        if (tmp.length > 5) {
            Store.addNotification({
                title: "Error",
                message: "Maximum 5 files to upload",
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: false
                }
            });
        }
        for (let i = 0; i < tmp.length; i++) {
            if (i >= 5) continue
            arr.push(URL.createObjectURL(tmp[i]))
        }

        SetInputForm({
            ...InputForm,
            [event.target.name]: event.target.files,
            docPreview: arr
        })
    }

    async function handleSubmit() {
        if (draftToHtml(convertToRaw(content.getCurrentContent())).length < 10) {
            Store.addNotification({
                title: "Incomplete form",
                message: "Description too short",
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            });

            return;
        }
        const formData = new FormData();
        formData.append('name', InputForm.name);
        formData.append('description', draftToHtml(convertToRaw(content.getCurrentContent())));
        formData.append('pic', InputForm.pic);
        formData.append('picNumber', InputForm.picNumber);
        formData.append('category', InputForm.category);
        for (let i = 0; i < InputForm.documents.length; i++) {
            const e = InputForm.documents[i];
            formData.append('documents', e);
        }
        await dispatch(createCase(formData));
    }

    useEffect(() => {
        if (category?.success) {
            let arr: { value: number | null, label: string | null }[] = []
            const categories = category.payload as CategoryInterface[]
            for (let i = 0; i < categories.length; i++) {
                const e = categories[i];
                arr.push({
                    value: e.id,
                    label: e.name
                })
            }
            setOptions(arr)
        }

        if (created?.success) {
            SetInputForm({
                name: '',
                description: '',
                pic: '',
                picNumber: '',
                documents: [],
                docPreview: [] as string[],
                category: ''
            })
            setContent(EditorState.createEmpty());
        }
        if (errors) {
            const listErr: [] = errors.validationErrors ?? []
            listErr.forEach((e: any) => {
                Store.addNotification({
                    title: errors.message,
                    message: e.msg,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                });
            })

        }
    }, [created?.message, created?.success, category, error, errors])
    return (
        <>
            <form className="max-w-full p-2 bg-white bg-opacity-25 rounded">
                <p className="mb-8 text-2xl font-light text-black">
                    Create new case
                </p>
                <div className="mb-4 grid grid-cols-5 gap-4">
                    <div className="md:col-span-3 col-span-5">
                        <Input autoFocus onChange={handleChange} value={InputForm.name} label="Subject" suffixIcon={<IconWarning className="absolute text-red-500 right-2 bottom-3" />} required={true} type="text" name="name" placeholder="new subject" />
                    </div>
                    <div className="md:col-span-1 col-span-5">
                        <Input onChange={handleChange} value={InputForm.pic} label="PIC" suffixIcon={<IconWarning className="absolute text-red-500 right-2 bottom-3" />} required={false} type="text" name="pic" placeholder="PIC" />
                    </div>
                    <div className="md:col-span-1 col-span-5">
                        <Input onChange={handleChange} value={InputForm.picNumber} label="Phone" suffixIcon={<IconWarning className="absolute text-red-500 right-2 bottom-3" />} required={false} type="tel" name="picNumber" placeholder="PIC Number" />
                    </div>
                </div>
                <div className="mb-2 shadow-sm">
                    <Editor
                        editorState={content}
                        // onEditorStateChange={data => onEditStateChange(data)}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="h-36 p-2"
                        onEditorStateChange={handleEditor}
                    />
                </div>
                <div className="mb-4 grid grid-cols-5 gap-4">
                    <div className="relative md:col-span-3 col-span-5">
                        <label className="text-gray-700">
                            Category
                        </label>
                        <Select onChange={(e) => SetInputForm({
                            ...InputForm,
                            category: e?.value as unknown as string
                        })} options={Options} className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-1 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" />
                    </div>
                    <div className="relative md:col-span-2 col-span-5">
                        <label className="text-gray-700">
                            Attachment (Optional)
                        </label>
                        <input onChange={handleupload} type="file" accept="image/*" className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="documents" multiple />
                    </div>

                </div>
                <div className="mb-4 flex flex-row gap-2">
                    {
                        InputForm.docPreview?.map((e, i) => {
                            return (
                                <img className="w-48 h-48" key={`preview-${i}`} src={e} alt="attachment"></img>
                            )
                        })
                    }
                </div>
                <div className="flex items-center justify-between mt-4">
                    <ButtonIcon variant="info" onClick={handleSubmit} text="Create" />
                </div>
            </form>
        </>
    )
}

export default CreateCase;