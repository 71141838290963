import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom"
import { useTypedSelector } from "../../hooks/useTypeSelector";
import { closeCase, getSingleCase } from "../../redux/actionCreators/caseAction";
import Page404 from "../404";
import DefaultModal from "../../components/Modal/Modal";
import getApi from "../../commons/axios/getApi";
import { Store } from "react-notifications-component";
import { createHistory } from "../../redux/actionCreators/historyAction";
import { checkAuth } from "../../redux/actionCreators/authAction";

const RoomCase = () => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const [openImage, setopenImage] = useState(false);
    const [showClosemenu, setshowClosemenu] = useState(false);
    const [Image, setImage] = useState<string | null>(null);
    const { authenticated } = useTypedSelector((state) => state.checkAuth);
    const { singleCase, errors, status, loading } = useTypedSelector((state) => state.case);
    const history = useTypedSelector((state) => state.history);
    const [InputForm, SetInputForm] = useState({
        caseId: id,
        progress: 'Progress checking',
        description: '',
        docPreview: [] as string[],
        documents: []
    })
    const inputFileRef = useRef<any>(null);

    function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        SetInputForm({
            ...InputForm,
            [event.target.name]: event.target.value,
        })
    }

    function closeHandle() {
        const formData = new FormData();
        formData.append('name', singleCase?.payload.name as string);
        formData.append('description', singleCase?.payload.description as string);
        formData.append('category', singleCase?.payload.category as unknown as string);
        formData.append('status', '3');

        dispatch(closeCase(id as unknown as number, formData));
        setshowClosemenu(false);
    }

    function handleupload(event: React.ChangeEvent<HTMLInputElement>) {
        let tmp = event.target.files ?? []
        let arr = []
        if (tmp.length > 5) {
            Store.addNotification({
                title: "Error",
                message: "Maximum 5 files to upload",
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: false
                }
            });
        }
        for (let i = 0; i < tmp.length; i++) {
            if (i >= 5) continue
            arr.push(URL.createObjectURL(tmp[i]))
        }

        SetInputForm({
            ...InputForm,
            [event.target.name]: event.target.files,
            docPreview: arr
        })
    }
    const showImage = async (filename: string) => {
        setopenImage(true)
        const { data } = await getApi.get(`/images/case?filename=${filename}`, {
            responseType: 'blob'
        });
        let base64data: string | ArrayBuffer | null;
        const fileReaderInstance = new FileReader();
        fileReaderInstance.readAsDataURL(data);
        fileReaderInstance.onload = async () => {
            base64data = fileReaderInstance.result;
            setImage(base64data as string)
        }
    }
    useEffect(() => {
        if (!singleCase?.success && !errors) {
            dispatch(getSingleCase(id));
        }
        if (history.created?.success) {
            SetInputForm({
                caseId: id,
                progress: 'Progress checking',
                description: '',
                docPreview: [] as string[],
                documents: []
            })

            // setContent(EditorState.createEmpty());
        }
        dispatch(checkAuth())
    }, [dispatch, errors, history, id, singleCase?.success])

    async function handleSubmit() {
        const formData = new FormData();
        formData.append('caseId', InputForm.caseId as string);
        formData.append('description', InputForm.description);
        formData.append('progress', InputForm.progress);
        formData.append('status', '2');
        for (let i = 0; i < InputForm.documents.length; i++) {
            const e = InputForm.documents[i];
            formData.append('documents', e);
        }

        await dispatch(createHistory(formData));
        await dispatch(getSingleCase(id));
    }

    const CloseButton = () => {
        return (
            <>
                <svg
                    onClick={() => setshowClosemenu(!showClosemenu)}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="icon-dots-vertical w-10 h-10 mt-2 mr-2 hover:bg-info-300 rounded-xl cursor-pointer"
                >
                    <path
                        className="text-green-100 fill-current"
                        fillRule="evenodd"
                        d="M12 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                    />
                </svg>
                <div className={`${showClosemenu ? '' : 'hidden'} origin-top-right absolute right-16 mt-12 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5`}>
                    <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <button onClick={closeHandle} type="button" className="flex items-center block w-full px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span className="flex flex-col ml-10">
                                <span>
                                    Close case
                                </span>
                            </span>
                        </button>
                    </div>
                </div>
            </>
        )
    }

    if (status === 404) {
        return (
            <Page404 />
        )
    } else {
        return (
            <>
                <DefaultModal open={openImage} setopen={setopenImage}>
                    <img alt="preview-attachment" src={Image as string}></img>
                </DefaultModal>
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3">
                        <div className="overscroll-behavior: none;">
                            <div
                                className={`w-full h-full ${singleCase?.payload.status as number >= 3 ? 'bg-danger-400' : 'bg-info-400'}  h-16 pt-2 text-white flex justify-between shadow-md inset-y-0 overscroll-behavior: none`}
                            >
                                {/* <!-- back button --> */}
                                <Link to="/opened-case">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="w-12 h-12 my-1 text-green-100 ml-2"
                                    >
                                        <path
                                            className="text-green-100 fill-current"
                                            d="M9.41 11H17a1 1 0 0 1 0 2H9.41l2.3 2.3a1 1 0 1 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.42 1.4L9.4 11z"
                                        />
                                    </svg>
                                </Link>
                                <div className="my-3 text-green-100 font-bold text-md tracking-wide mx-4 break-words">
                                    {singleCase?.payload.name} - {singleCase?.payload.caseNumber}
                                </div>
                                {/* <!-- 3 dots --> */}
                                <>
                                    {
                                        singleCase?.payload.case_status.name !== 'closed' ? <CloseButton /> : <></>
                                    }
                                </>
                            </div>
                            <div className="mt-2 mb-2 h-96 overflow-y-auto ">
                                <div className="flex flex-col-reverse justify-end h-full">
                                    {
                                        singleCase?.payload.case_histories.map((e, i) => {
                                            return (
                                                <div key={`chat-${i}`} className="clearfix">
                                                    <div className={`${e.createdBy === authenticated?.email ? 'bg-green-200 float-right ' : 'bg-gray-300 float-left'}                                                 
                                                w-3/4 mx-4 my-2 p-2 rounded-lg clearfix break-words`}> 
                                                        <div className="float-right text-sm text-gray">
                                                            {e.createdBy === authenticated?.email ? '' : e.createdBy}
                                                        </div>
                                                        <div className="mt-4" dangerouslySetInnerHTML={{ __html: e.description }} />
                                                        {
                                                            <div className="mt-4">
                                                                {/* Attachment : */}
                                                                {
                                                                    e.case_documents?.map((v, y) => {
                                                                        return <div onClick={() => showImage(v.image)} className="hover:text-sky-500 text-sky-500 md:text-gray-800 cursor-pointer" key={`imgchat-${y}`}>{v.image}</div>
                                                                    })
                                                                }

                                                            </div>

                                                        }
                                                        <div className="float-right mt-4 text-xs text-gray">
                                                            {new Date(e.createdAt as string).toLocaleString()}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={`w-full h-36 flex justify-between bg-info-100 b-0 ${singleCase?.payload.status as number >= 3 ? 'hidden' : ''}`}
                        // style="bottom: 0px;"
                        >
                            <textarea
                                onChange={handleChange}
                                className="flex-grow m-2 py-2 px-4 mr-1 rounded-lg border border-gray-300 bg-gray-200 resize-none"
                                rows={1}
                                placeholder="Message..."
                                value={InputForm.description}
                                name="description"
                            // style="outline: none;"
                            />
                            <input hidden ref={inputFileRef} onChange={handleupload} type="file" accept="image/*" className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="documents" multiple />
                            <button className="m-2" onClick={() => inputFileRef.current!.click()}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-gray-700 h-12 w-12"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                </svg>
                            </button>
                            <button className="m-2" onClick={handleSubmit}>
                                <svg
                                    className="svg-inline--fa text-info-400 fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="paper-plane"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div>
                        <div className="mb-4 flex flex-row gap-2">
                            {
                                InputForm.docPreview?.map((e, i) => {
                                    return (
                                        <img className="w-48 h-48" key={`preview-${i}`} src={e} alt="attachment"></img>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default RoomCase