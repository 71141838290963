import { ForwardRefRenderFunction } from "react";
import { InputProps } from "../../../types/interface";

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ 
    label,
    isError, 
    disabled,
    id,
    required,
    suffixIcon,
    errorText,
    className,
    ...otherProps 
}, ref) => {
    return (
        <div className={`${isError ? 'pb-4' : 'pb-0'}`}>
            <div className={`relative ${disabled ? 'opacity-50 pointer-events-none' : ''}`}>
                <label htmlFor={id} className="text-gray-700">
                    {label}
                    {required ?
                        <span className="text-red-500 required-dot">
                            *
                        </span> : <></>}
                </label>
                <input {...otherProps} disabled={disabled} id={id} className={`${isError ? 'ring-red-500' : ''} rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"`} />
                {isError ? suffixIcon : ''}
                <p className="absolute text-sm text-red-500 -bottom-6">
                    {isError ? errorText : ''}
                </p>
            </div>
        </div>
    )
}

export default Input