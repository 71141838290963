const Page404 = () => {
    return (
        <>
            <div className="text-9xl flex justify-center">
                404
            </div>
            <div className="text-xl flex justify-center">
                Page not found
            </div>
        </>
    )
}

export default Page404;