import { Store } from 'react-notifications-component';
import { Dispatch } from 'redux';
import getApi from '../../commons/axios/getApi';
import { actionCase, caseActionType } from '../actionTypes/caseActionType';

export const createCase = (params: any) => {
    return async (dispatch: Dispatch<actionCase>) => {
        dispatch({
            type: caseActionType.CASE_LOADING
        });

        try {
            const { data } = await getApi.post('/case/create', params);

            dispatch({
                type: caseActionType.CREATED_CASE,
                payload: data
            });
            if (data.success) {
                Store.addNotification({
                    title: "Saved",
                    message: data?.message,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false
                    }
                });
                dispatch({
                    type: caseActionType.CREATED_CASE,
                    payload: data
                });
            } else {
                dispatch({
                    type: caseActionType.ERROR_CASE,
                    payload: data
                });
            }
        } catch (err: any) {
            dispatch({
                type: caseActionType.ERROR_CASE,
                payload: err.message
            });
        }
    }
}
export const closeCase = (id: number, params: any) => {
    return async (dispatch: Dispatch<actionCase>) => {
        dispatch({
            type: caseActionType.CASE_LOADING
        });

        try {
            const { data } = await getApi.put('/case/' + id, params);
            dispatch({
                type: caseActionType.UPDATE_CASE,
                payload: data
            });
            if (data.success) {
                Store.addNotification({
                    title: "Closed",
                    message: data?.message,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false
                    }
                });
                dispatch({
                    type: caseActionType.UPDATE_CASE,
                    payload: data
                });
            } else {
                dispatch({
                    type: caseActionType.ERROR_CASE,
                    payload: data
                });
            }
        } catch (err: any) {
            dispatch({
                type: caseActionType.ERROR_CASE,
                payload: err.message
            });
        }
    }
}
export const getCase = (page?: number, limit?: number, keyword?: {
    title?: string,
    description?: string,
    status?: number,
    skipclosed?: string
}) => {
    return async (dispatch: Dispatch<actionCase>) => {
        dispatch({
            type: caseActionType.CASE_LOADING
        });
        let url: string = ''
        if (keyword?.title) url = url + `&title=${keyword.title}`;
        if (keyword?.description) url = url + `&description=${keyword.description}`;
        if (keyword?.status) url = url + `&status=${keyword.status}`;
        if (keyword?.skipclosed) url = url + `&skipclosed=${keyword.skipclosed}`;
        if (limit) url = url + `&rows=${limit}`;
        try {
            const { data } = await getApi.get(`/case?page=${page ? page : 1}${url}`);
            if (data.success) {
                dispatch({
                    type: caseActionType.READ_CASES,
                    payload: data
                });
            } else {
                dispatch({
                    type: caseActionType.ERROR_CASE,
                    payload: data
                });
            }

        } catch (err: any) {
            dispatch({
                type: caseActionType.ERROR_CASE,
                payload: err.message
            });
        }
    }
}
export const getSingleCase = (id?: string) => {
    return async (dispatch: Dispatch<actionCase>) => {
        dispatch({
            type: caseActionType.CASE_LOADING
        });

        try {
            const { data, ...params } = await getApi.get(`/case/${id}`);

            if (data.success) {
                dispatch({
                    type: caseActionType.READ_SINGLE_CASE,
                    payload: data
                });
            } else {
                // console.log(params)
                dispatch({
                    type: caseActionType.ERROR_CASE,
                    payload: data,
                    status: params.status
                });
            }

        } catch (err: any) {

            dispatch({
                type: caseActionType.ERROR_CASE,
                payload: err.message,
            });
        }
    }
}