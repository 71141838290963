import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavContext } from '../../contexts/NavContext';
import '../../index.css';
import DropDownMenuProfile from './DropdownProfile';

interface Props {
    //boolean to always open ddm (for presentation)
    forceOpen?: boolean;
    label?: string;
    withDivider?: boolean;
    icon?: JSX.Element;
    items: DDMItem[];
    withBackground?: boolean;
}

export interface DDMItem {
    icon?: JSX.Element;
    label: string;
    desc?: string;
    link?: string;
}

function useOutsideAlerter(ref: any) {
    const NavCtx = useContext(NavContext);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: { target: any; }) {
            if (ref.current && !ref.current.contains(event.target)) {
                NavCtx?.SetShowProfileMenu(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [NavCtx, ref]);
}
const ShowDropdownProfile = (Props: { isOpen: boolean }) => {
    const { isOpen } = Props;
    if (isOpen) {
        return <DropDownMenuProfile />
    } else {
        return <></>
    }

}
const Header = (props: Props) => {
    const NavCtx = useContext(NavContext)

    const wrapperRef = useRef<HTMLHeadingElement>(null);
    useOutsideAlerter(wrapperRef);

    return (

        <div>
            <nav className="bg-white dark:bg-gray-800 my-4 mx-4 shadow">
                <div className="max-w-7xl mx-auto px-8">
                    <div className="flex flex-row-reverse items-center justify-between h-16">
                        <div className="block">
                            <div className="ml-4 flex items-center md:ml-6">
                                <div ref={wrapperRef} className="ml-3 relative">
                                    <div className="relative inline-block text-left">
                                        <div>
                                            <button onClick={() => NavCtx?.SetShowProfileMenu(!NavCtx.ShowProfileMenu)} type="button" className="  flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500" id="options-menu">
                                                <svg width="20" fill="currentColor" height="20" className="text-gray-800" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1523 1339q-22-155-87.5-257.5t-184.5-118.5q-67 74-159.5 115.5t-195.5 41.5-195.5-41.5-159.5-115.5q-119 16-184.5 118.5t-87.5 257.5q106 150 271 237.5t356 87.5 356-87.5 271-237.5zm-243-699q0-159-112.5-271.5t-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5 271.5-112.5 112.5-271.5zm512 256q0 182-71 347.5t-190.5 286-285.5 191.5-349 71q-182 0-348-71t-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                        <ShowDropdownProfile isOpen={NavCtx?.ShowProfileMenu as boolean} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="-mr-2 flex lg:hidden">
                            <button onClick={() => NavCtx?.SetShowSidebar(true)} className="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
                                <svg width="20" height="20" fill="currentColor" className="h-8 w-8" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>

    );
};
export default Header;