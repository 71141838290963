import { Store } from 'react-notifications-component';
import { Dispatch } from 'redux';
import getApi from '../../commons/axios/getApi';
import { actionHistory, historyActionType } from '../actionTypes/historyActionType';

export const createHistory = (form: any) => {
    return async (dispatch: Dispatch<actionHistory>) => {
        dispatch({
            type: historyActionType.HISTORY_LOADING
        });

        try {
            const { data } = await getApi.post('/case-history/create', form);

            dispatch({
                type: historyActionType.CREATED_HISTORY,
                payload: data
            });
            if (data.success) {
                Store.addNotification({
                    title: "Saved",
                    message: data?.message,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false
                    }
                });
                dispatch({
                    type: historyActionType.CREATED_HISTORY,
                    payload: data
                });
            } else {
                const listErr: [] = data.validationErrors ?? []
                listErr.forEach((e: any) => {
                    Store.addNotification({
                        title: data.message,
                        message: e.msg,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                            onScreen: true
                        }
                    });
                })
                dispatch({
                    type: historyActionType.ERROR_HISTORY,
                    payload: data
                });
            }
        } catch (err: any) {
            dispatch({
                type: historyActionType.ERROR_HISTORY,
                payload: err.message
            });
        }
    }
}