import { CategoryInterface } from "../../interfaces/Category";

export interface CategoryResponse {
    success: boolean | null,
    message?: string,
    payload: CategoryInterface | CategoryInterface[],
    errors: any | null,
    validationErrors?: any,
}

export enum categoryActionType {
    GET_POST_CATEGORY_PENDING = 'GET_POST_CATEGORY_PENDING',
    GET_POST_CATEGORY_SUCCESS = 'GET_POST_CATEGORY_SUCCESS',
    GET_POST_CATEGORY_FAIL = 'GET_POST_CATEGORY_FAIL'
}

interface actionPending {
    type: categoryActionType.GET_POST_CATEGORY_PENDING;
}

interface actionSuccess {
    type: categoryActionType.GET_POST_CATEGORY_SUCCESS;
    payload: CategoryResponse;
}

interface actionFail {
    type: categoryActionType.GET_POST_CATEGORY_FAIL;
    payload: CategoryResponse;
}

export type actionCategory = actionPending | actionSuccess | actionFail;