import { UserInterface } from "../../interfaces/User";

export interface AuthResponse {
    success: boolean | null,
    message?: string,
    payload: UserInterface,
    errors: any | null,
    validationErrors?: any,
}

export enum authActionType {
    GET_POST_AUTH_PENDING = 'GET_POST_AUTH_PENDING',
    GET_POST_AUTH_SUCCESS = 'GET_POST_AUTH_SUCCESS',
    GET_POST_AUTH_FAIL = 'GET_POST_AUTH_FAIL'
}

interface actionPending {
    type: authActionType.GET_POST_AUTH_PENDING;
}

interface actionSuccess {
    type: authActionType.GET_POST_AUTH_SUCCESS;
    payload: AuthResponse;
}

interface actionFail {
    type: authActionType.GET_POST_AUTH_FAIL;
    payload?: AuthResponse;
}

export type actionAuth = actionPending | actionSuccess | actionFail;