import ButtonIcon from "../../components/Buttons/ButtonIcon";
import Link from "../../components/Typography/Link";
import Input from "../../components/Forms/Inputs/Input";
import IconWarning from "../../components/Icons/Warning";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useTypedSelector } from "../../hooks/useTypeSelector";
import { useDispatch } from "react-redux";
import { checkAuth, postLogin } from "../../redux/actionCreators/authAction";
import { Store } from "react-notifications-component";

const LoginPage = () => {
    console.log(process.env.REACT_APP_AUTH_SERVER)
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const { login, error } = useTypedSelector((state) => state.auth);
    const [InputForm, SetInputForm] = useState({
        email: {
            value: '',
            msgError: ''
        },
        password: {
            value: '',
            msgError: ''
        },
    });

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        SetInputForm({
            ...InputForm,
            [event.target.name]: {
                value: event.target.value,
                msgError: event.target.getAttribute("data-error")
            }
        }
        )
    }

    useEffect(() => {
        if (login?.success) {
            
            const user = login.payload.user_profile;

            dispatch(checkAuth());

            Store.addNotification({
                title: "Welcome back!",
                message: "Glad see you again, " + user?.firstName + ' ' + user?.lastName,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: false
                }
            });

            return navigate('/');
        }
        if (error.status) {
            Store.addNotification({
                message: error.message,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: false
                }
            })
            error.validations?.map((e: { msg: string }) => {
                return (
                    Store.addNotification({
                        title: "Validation error",
                        message: e.msg,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: false
                        }
                    })
                )
            })
        }
    }, [error,login])


    async function handleSubmit() {
        const { email, password } = InputForm;
        await dispatch(postLogin({
            email: email.value,
            password: password.value
        }))
    }
    function onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key.toLowerCase() === 'enter') {
            return handleSubmit()
        }
    }
    return (
        <div className="w-full h-screen fixed font-sans bg-cover bg-landscape">
            <div className="container flex items-center justify-center flex-1 h-full mx-auto">
                <div className="w-full max-w-lg">
                    <div className="leading-loose">
                        <form className="max-w-sm p-10 m-auto bg-white bg-opacity-25 rounded shadow-xl">
                            <p className="mb-8 text-2xl font-light text-center text-black">
                                Login
                            </p>
                            <div className="mb-2">
                                <Input onChange={handleChange} isError={error.status} value={InputForm.email.value} label="Email" suffixIcon={<IconWarning className="absolute text-red-500 right-2 bottom-3" />} required={true} type="email" name="email" placeholder="Input your email" />
                            </div>
                            <div className="mb-2">
                                <Input onKeyDown={onKeyDown} isError={error.status} value={InputForm.password.value} onChange={handleChange} label="Password" type="password" name="password" placeholder="Enter your password" />
                            </div>
                            <div className="flex items-center justify-between mt-4">
                                <ButtonIcon variant="info" onClick={handleSubmit} text="Sign in" />
                            </div>
                            <div className="text-center">
                                <a target="blank" href="https://helper.pwkt.citra.net.id" className="right-0 inline-block text-sm font-light align-baseline text-500 hover:text-gray-800" children="Create new account" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default LoginPage;