import React, { useEffect, useState } from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import getApi from "../../commons/axios/getApi";

const ModalImage = ({ open, setopen, children, ...props }) => {
    return (
        <>
            <Modal size="lg" active={open} toggler={() => setopen(false)}>
                <ModalHeader toggler={() => setopen(false)}>
                    Preview
                </ModalHeader>
                <ModalBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                        {children}
                    </p>
                </ModalBody>
            </Modal>
        </>
    );
}

export default ModalImage