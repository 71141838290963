import { createContext, useState } from "react"

interface NavProviderProps {
    children: JSX.Element
}
interface NavContextInterface {
    ShowSidebar: boolean,
    ShowProfileMenu: boolean,
    SetShowProfileMenu: React.Dispatch<React.SetStateAction<boolean>>,
    SetShowSidebar: React.Dispatch<React.SetStateAction<boolean>>,
}
export const NavContext = createContext<NavContextInterface | null>(null)

export const NavProvider = (props: NavProviderProps) => {
    const [ShowProfileMenu, SetShowProfileMenu] = useState(false);
    const [ShowSidebar, SetShowSidebar] = useState(false);

    return (
        <NavContext.Provider value={{
            ShowProfileMenu, ShowSidebar, SetShowProfileMenu, SetShowSidebar,
        }}
        >
            {props.children}
        </NavContext.Provider>
    )
}

