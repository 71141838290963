import { useContext, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { NavContext } from "../../contexts/NavContext";

const ActiveMenu = (props: menuItem) => {
    const { label, link, icon } = props;
    const NavCtx = useContext(NavContext)

    return (
        <Link className="w-full font-thin uppercase text-blue-500 flex items-center p-4 my-2 transition-colors duration-200 justify-start bg-gradient-to-r from-white to-blue-100 border-r-4 border-blue-500 dark:from-gray-700 dark:to-gray-800 border-r-4 border-blue-500" to={link}>
            <span className="text-left">
                {icon}
            </span>
            <span className="mx-4 text-sm font-normal">
                {label}
            </span>
        </Link>
    )
}
const IdleMenu = (props: menuItem) => {
    const NavCtx = useContext(NavContext)

    const { label, link, icon } = props;

    return (

        <Link onClickCapture={() => NavCtx?.SetShowSidebar(false)} className="w-full font-thin uppercase text-gray-500 dark:text-gray-200 flex items-center p-4 my-2 transition-colors duration-200 justify-start hover:text-blue-500" to={link}>
            <span className="text-left">
                {icon}
            </span>
            <span className="mx-4 text-sm font-normal">
                {label}
            </span>
        </Link>
    )
}

interface Props {
    //boolean to always open ddm (for presentation)
    label: string;
    isActive?: boolean;
    icon: JSX.Element;
    link: string,
}

interface menuItem {
    label: string,
    link: string,
    icon?: JSX.Element,
}
const SidebarMenuItem = (props: Props) => {
    const { label, icon, link } = props;
    let isActive: boolean = false;
    const location = useLocation();

    if (link === location.pathname) {
        return (
            <ActiveMenu label={label} link={link} icon={icon} />
        );
    } else {
        return (
            <IdleMenu label={label} link={link} icon={icon} />
        )
    }
}

export default SidebarMenuItem;