import { actionCase, caseActionType, caseResponse, casesResponse } from "../actionTypes/caseActionType";

interface State {
    loading: boolean;
    cases?: casesResponse | null;
    singleCase?: caseResponse | null;
    updated?: caseResponse | null;
    deleted?: caseResponse | null;
    created?: caseResponse | null;
    errors?: any;
    status?: number;
}

const initialState = {
    loading: false,
    cases: null,
    singleCase: null,
    updated: null,
    deleted: null,
    created: null,
    errors: null
}

const CaseReducer = (state: State = initialState, action: actionCase): State => {
    switch (action.type) {
        case caseActionType.CASE_LOADING:
            return {
                loading: true
            }
        case caseActionType.CREATED_CASE:
            return {
                loading: false,
                created: action.payload,
            }
        case caseActionType.READ_CASES:
            return {
                loading: false,
                cases: action.payload
            }
        case caseActionType.READ_SINGLE_CASE:
            return {
                loading: false,
                singleCase: action.payload
            }
        case caseActionType.UPDATE_CASE:
            return {
                loading: false,
                updated: action.payload
            }
        case caseActionType.DELETE_CASES:
            return {
                loading: false,
                deleted: action.payload
            }
        case caseActionType.ERROR_CASE:
            return {
                loading: false,
                errors: action.payload,
                status: action.status
            }
        default:
            return state;
    }
}

export default CaseReducer;