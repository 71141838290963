import { CaseHistory, HistoriesPaginationInterface } from "../../interfaces/CaseHistory";

export interface historyResponse {
    success: boolean | null,
    message?: string,
    payload: CaseHistory,
    errors: any | null,
    validationErrors?: any,
}
export interface historiesResponse {
    success: boolean | null,
    message?: string,
    payload: HistoriesPaginationInterface,
    errors: any | null,
    validationErrors?: any,
}
export enum historyActionType {
    HISTORY_LOADING = 'HISTORY_LOADING',
    CREATED_HISTORY = 'CREATED_HISTORY',
    READ_HISTORIES = 'READ_HISTORIES',
    ERROR_HISTORY = 'ERROR_HISTORY'
}

interface actionLoading {
    type: historyActionType.HISTORY_LOADING;
}

interface actionCreate {
    type: historyActionType.CREATED_HISTORY;
    payload: historyResponse;
}

interface actionReadAll {
    type: historyActionType.READ_HISTORIES;
    payload: historiesResponse;
}

interface actionError {
    type: historyActionType.ERROR_HISTORY;
    payload: historyResponse;
    status?: number
}

export type actionHistory = actionLoading | actionCreate | actionReadAll | actionError;