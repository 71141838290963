
import { actionAuth, authActionType, AuthResponse } from '../actionTypes/authActionType';
interface State {
    login: AuthResponse | null;
    loading: boolean;
    error: {
        status: boolean,
        message?: string,
        validations?: any
    };
}

const initialState = {
    login: {
        success: false,
        payload: {
            id: null,
            email: null,
            username: null,
            phone: null,
            createdAt: null,
            updatedAt: null,
            user_profile: null
        },
        errors: null,
    },
    loading: false,
    error: {
        status: false,
    }
}

const AuthReducer = (state: State = initialState, action: actionAuth): State => {
    switch (action.type) {
        case authActionType.GET_POST_AUTH_PENDING:
            return {
                loading: true,
                login: initialState.login,
                error: {
                    status: false
                }
            }
        case authActionType.GET_POST_AUTH_SUCCESS:
            return {
                loading: false,
                login: action.payload,
                error: {
                    status: false
                }
            }
        case authActionType.GET_POST_AUTH_FAIL:
            return {
                loading: false,
                login: null,
                error: {
                    status: true,
                    message: action.payload?.errors ?? action.payload?.message,
                    validations: action.payload?.validationErrors,
                },
            }
        default:
            return state;
    }
}

export default AuthReducer;