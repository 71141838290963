import { ForwardRefRenderFunction, useEffect, useState } from "react";
import { ButtonProps } from "../../types/interface";
import color from "../../color.json";
const ButtonIcon: ForwardRefRenderFunction<HTMLInputElement, ButtonProps> = ({
    text,
    prefixIcon,
    variant,
    ...otherProps
}, ref) => {
    const [Color, setColor] = useState({
        main: `bg-gray-600`,
        hover: `hover:bg-gray-700`,
        focus: `focus:ring-gray-500`,
        focueOffset: `focus:ring-offset-gray-200`
    })

    useEffect(() => {
        if (variant === `danger`) {
            setColor({
                main: `bg-red-600`,
                hover: `hover:bg-red-700`,
                focus: `focus:ring-red-500`,
                focueOffset: `focus:ring-offset-red-200`
            });
        } else if (variant === `warning`) {
            setColor({
                main: `bg-amber-600`,
                hover: `hover:bg-amber-700`,
                focus: `focus:ring-amber-500`,
                focueOffset: `focus:ring-offset-amber-200`
            });
        } else if (variant === `success`) {
            setColor({
                main: `bg-success-600`,
                hover: `hover:bg-success-700`,
                focus: `focus:ring-success-500`,
                focueOffset: `focus:ring-offset-success-200`
            });
        } else if (variant === `info`) {
            setColor({
                main: `bg-sky-600`,
                hover: `hover:bg-sky-700`,
                focus: `focus:ring-sky-500`,
                focueOffset: `focus:ring-offset-sky-200`
            });
        } else {
            setColor({
                main: `bg-sky-600`,
                hover: `hover:bg-sky-700`,
                focus: `focus:ring-sky-500`,
                focueOffset: `focus:ring-offset-sky-200`
            })
        }

    }, [variant])


    return (
        <button type="button" className={`py-2 px-4 flex justify-center items-center ${Color.main} ${Color.hover} ${Color.focus} ${Color.focueOffset} text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg`} {...otherProps}>
            {prefixIcon}
            {text}
        </button>
    )
}

export default ButtonIcon;