import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DefaultModal from "../components/Modal/Modal";
import { useTypedSelector } from "../hooks/useTypeSelector";
import AdminLayout from "../layouts/AdminLayout";
import AuthLayout from "../layouts/AuthLayout";
import { PrivateAuth, RouteNoAuth } from "../middlewares/auth";
import Page404 from "../pages/404";
import LoginPage from "../pages/Auth/login";
import LogoutPage from "../pages/Auth/logout";
import HistoryCase from "../pages/Cases/HistoryCase";
import OpenCase from "../pages/Cases/OpenCase";
import RoomCase from "../pages/Cases/RoomCase";
import DashboardPage from "../pages/Dashboard";
import CreateCase from "../pages/NewCase/create";
import { checkAuth } from "../redux/actionCreators/authAction";

const RouteApp = () => {
    const dispatch = useDispatch();
    const { unauthenticated, authenticated } = useTypedSelector((state) => state.checkAuth);
    const [firstload, setfirstload] = useState(true)
    useEffect(() => {
        dispatch(checkAuth());
        // console.log(unauthenticated)
    }, [unauthenticated])
    return (
        <>
            <Routes>
                <Route path="/" element={<RequireAuth><AdminLayout /></RequireAuth>}>
                    <Route index element={<DashboardPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="/new-case" element={<CreateCase />} />
                    <Route path="/opened-case" element={<OpenCase />} />
                    <Route path="/history-case" element={<HistoryCase />} />
                    <Route path="/history-case/:id" element={<RoomCase />} />
                    <Route path="/opened-case/:id" element={<RoomCase />} />
                    <Route path="*" element={<Page404 />} />
                </Route>
                <Route path="/" element={<RouteNoAuth auth={unauthenticated}><AuthLayout /></RouteNoAuth>}>
                    <Route path="/login" element={<LoginPage />} />
                </Route>
            </Routes>
        </>
    )
}
export default RouteApp;

function RequireAuth({ children }: { children: JSX.Element }) {
    const { unauthenticated, authenticated } = useTypedSelector((state) => state.checkAuth);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    
    useEffect(() => {
        function checkUserData() {
            dispatch(checkAuth())
            const item = localStorage.getItem('access_token')
            if (item === null) {
                navigate('login')
            }
        }
        function checkauth(){
            dispatch(checkAuth())
        }
        window.addEventListener('storage', checkUserData)
        window.addEventListener('click', checkauth)
        // console.log(unauthenticated)

        return () => {
            window.removeEventListener('storage', checkUserData)
            window.removeEventListener('click', checkauth)
        }
    }, [])

    if (unauthenticated) {
        return <Navigate to={'/login'} />
    }
    return children;


}