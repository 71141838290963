import axios, { AxiosInstance } from "axios";

function authApi(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(
        async (config: any) => {
            let token:string | null = "initial token"
            let refreshToken:string | null = "initial token"
            
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('access_token');
                refreshToken = localStorage.getItem('refresh_token');
            }
    
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
                config.headers['refreshtoken'] = refreshToken;
            }
            config.headers['Content-Type'] = 'application/json';
            config.headers['Accept'] = 'application/json';
            config.headers['Access-Control-Allow-Origin'] = '*';

            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            console.log(error)
            if (error.toString() === "Error: Network Error") return {
                success: false,
                errors: {
                    message: error.toString()
                }
            }
            return error.response
        }
    );

    return axiosInstance;
}

export default authApi(
    axios.create({
        baseURL: 'https://auth.pwkt.citra.net.id/api',
    })
)