import { NavProvider } from './contexts/NavContext';
import './index.css';
import "@material-tailwind/react/tailwind.css";
import 'react-notifications-component/dist/theme.css'
import RouteApp from './routes/RouteApp';
import { ReactNotifications } from 'react-notifications-component'

function App() {
  return (
    <NavProvider>
      <>
        <ReactNotifications />
        <RouteApp />
      </>
    </NavProvider>
  );
}

export default App;
