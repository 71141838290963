import { actionHistory, historiesResponse, historyActionType, historyResponse } from "../actionTypes/historyActionType";

interface State {
    loading: boolean;
    histories?: historiesResponse | null;
    created?: historyResponse | null;
    errors?: any;
    status?: number;
}

const initialState = {
    loading: false,
    histories: null,
    created: null,
    errors: null
}

const HistoryReducer = (state: State = initialState, action: actionHistory): State => {
    switch (action.type) {
        case historyActionType.HISTORY_LOADING:
            return {
                loading: true
            }
        case historyActionType.CREATED_HISTORY:
            return {
                loading: false,
                created: action.payload,
            }
        case historyActionType.READ_HISTORIES:
            return {
                loading: false,
                histories: action.payload
            }
        case historyActionType.ERROR_HISTORY:
            return {
                loading: false,
                errors: action.payload,
                status: action.status
            }
        default:
            return state;
    }
}

export default HistoryReducer;