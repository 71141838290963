import { useContext, useEffect, useRef } from "react";
import { NavContext } from "../../contexts/NavContext";
import IconDashboard from "../Icons/Dashboard";
import SidebarMenuItem from "../Menus/SidebarMenuItem";

function useOutsideAlerter(ref: any) {
    const NavCtx = useContext(NavContext);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: { target: any; }) {
            if (ref.current && !ref.current.contains(event.target)) {
                NavCtx?.SetShowSidebar(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [NavCtx, ref]);
}
interface MenuLists {
    label: string,
    icon: JSX.Element,
    link: string,
}
const Sidebar = (props: {
    data: MenuLists[]
}) => {
    const NavCtx = useContext(NavContext);
    const wrapperRef = useRef<HTMLHeadingElement>(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div ref={wrapperRef} className={`h-screen z-50 fixed scrollbar-thin scrollbar-thumb-indigo-500 scrollbar-track-gray-100 ${NavCtx?.ShowSidebar ? '' : 'hidden'} lg:block my-4 ml-4 shadow-lg w-80 overflow-auto`}>
            <div className="bg-white h-full rounded-2xl dark:bg-gray-700">
                <div className="flex items-center justify-center pt-6">
                <img alt="citranet" src="https://citra.net.id/cni-content/uploads/modules/siteconfig/logo_citra.png" />
                    
                </div>
                <nav className="my-6">
                    <div>
                        {
                            props.data.map((v, i) => {
                                return (
                                    <SidebarMenuItem key={'menu' + i} icon={v.icon} label={v.label} link={v.link} />
                                )
                            })
                        }
                    </div>
                </nav>
            </div>
        </div>

    )
}
export default Sidebar;