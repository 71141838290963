import { combineReducers } from 'redux';
import authReducer from './authReducer';
import CaseReducer from './caseReducer';
import CategoryReducer from './categoryReducer';
import checkAuthReducer from './checkAuthReducer';
import HistoryReducer from './historyReducer';

const reducers = combineReducers({
    auth: authReducer,
    checkAuth: checkAuthReducer,
    category:CategoryReducer,
    case:CaseReducer,
    history:HistoryReducer,
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;