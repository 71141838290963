import { useEffect } from "react";
import { Store } from "react-notifications-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypeSelector";
import { postLogout } from "../../redux/actionCreators/authAction";

const LogoutPage = () => {
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const { unauthenticated, process } = useTypedSelector((state) => state.checkAuth);

    useEffect(() => {
        dispatch(postLogout());

        if (!process) {
            if (unauthenticated) {
                Store.addNotification({
                    title: "You have been logged out",
                    message: "See you soon",
                    type: "warning",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false
                    }
                });
                return navigate('/login')
            }
        }
    }, [navigate, process, unauthenticated])
    return (
        <></>
    )
}

export default LogoutPage